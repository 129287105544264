.loader {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 130px;
}
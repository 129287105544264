.footer {
    padding: 0 8px;
    opacity: 0.5;
    font-size: 0.8rem;
}
.leftFooter {
    text-align: left;
}

.rightFooter {
    text-align: right;
}
